<template>
	<div class="features">
		<div class="desc" ref="desc">
			Get all the features
		</div>
		<div class="cta" @click="onClick" ref="cta">
			<img src="img/views/home/dot.svg"><span>Create a profile</span><img ref="arrow" src="img/views/home/icon-cta.svg">
		</div>
	</div>
</template>

<script>
import { Expo, gsap, Quad } from 'gsap/all'
import router from '@/router'

export default {
	name: 'FeaturePush',
	mounted () {
		gsap.set(this.$el, { x: 20 })
		this.show()
	},
	methods: {
		onClick () {
			router.push('/profile')
		},
		show () {
			gsap.fromTo(this.$refs.desc, { x: -70, opacity: 0 }, { delay: 0.3, ease: Expo.easeOut, duration: 0.8, x: 0, opacity: 1 })
			gsap.fromTo(this.$refs.cta, { x: -70, opacity: 0 }, { delay: 0.4, ease: Expo.easeOut, duration: 0.8, x: 0, opacity: 1 })
		},
		hide () {
			gsap.to(this.$refs.cta, { x: 20, opacity: 0, delay: 0.1, ease: Quad.easeOut, duration: 0.5 })
			gsap.to(this.$refs.desc, { x: 10, opacity: 0, delay: 0.1, ease: Quad.easeOut, duration: 0.5 })
			gsap.to(this.$refs.arrow, { x: 30, opacity: 0, delay: 0.1, ease: Quad.easeOut, duration: 0.5 })
		}
	}
}
</script>

<style lang="stylus" scoped>
	.features
		margin-left 20px
		margin-top 40px
		text-align left
		.desc
			font-family: 'HK Grotesk Pro';
			font-size: 12px;
			font-style: normal;
			font-weight: 400;
			line-height: 14px;
			letter-spacing: -0.03em;
			color #828282
		.cta
			span
				margin-left 7px
				margin-right 12px
		img
			display inline-block
			vertical-align middle
</style>
