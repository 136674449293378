<template>
	<div class="invite">
		<div class="middle">
			<img ref="left" src="img/views/home/illustration-left.png">
			<span ref="btn" @click="onClick">
				Invite a Friend
			</span>
			<img ref="right" src="img/views/home/illustration-right.png" class="right">
		</div>
		<div ref="text" class="credits">
			Get extra credits and learn together!
		</div>
	</div>
</template>

<script>
import { gsap, Quad } from 'gsap/all'
import router from '@/router'

export default {
	name: 'InviteFriendButton',
	mounted () {
		gsap.set(this.$refs.btn, { y: 20, opacity: 0 })
		gsap.set(this.$refs.text, { y: 30, opacity: 0 })
		this.show()
	},
	methods: {
		onClick () {
			router.push('invite')
		},
		show () {
			gsap.to(this.$refs.btn, { delay: 0.5, ease: Quad.easeOut, duration: 0.8, y: 0, opacity: 1 })
			gsap.to(this.$refs.text, { delay: 0.6, ease: Quad.easeOut, duration: 0.8, y: 0, opacity: 1 })
			gsap.fromTo(this.$refs.left, { y: '60', opacity: 0 }, { y: 0, opacity: 1, delay: 0.9, ease: Quad.easeOut, duration: 0.6 })
			gsap.fromTo(this.$refs.right, { y: '40', opacity: 0 }, { y: 0, opacity: 1, delay: 0.8, ease: Quad.easeOut, duration: 0.5 })
		},
		hide () {
			gsap.to(this.$refs.btn, { ease: Quad.easeOut, duration: 0.8, y: -20, opacity: 0 })
			gsap.to(this.$refs.text, { ease: Quad.easeOut, duration: 0.8, y: -10, opacity: 0 })
			gsap.to(this.$refs.left, { y: '-60', opacity: 0, ease: Quad.easeOut, duration: 0.6 })
			gsap.to(this.$refs.right, { y: '-40', opacity: 0, ease: Quad.easeOut, duration: 0.5 })
		}
	}
}
</script>

<style lang="stylus" scoped>
.invite
	color #fff
	cursor pointer
	user-select none
	width 100%
	text-align center
	margin-top 85px
	position relative
	img
		opacity 0
		will-change transform
		position absolute
		top -50px
		left 0
		width 101px
		&.right
			width 75px
			top -50px
			left auto
			right 0
	.credits
		margin-top 20px
		font-family: HK Grotesk Pro
		font-style: normal
		font-weight: normal
		font-size: 12px
		line-height: 14px
		letter-spacing: -0.03em
		color: #828282

	&:hover
		color #FAFAFA
	span
		color: #2E2E2E
		font-family: 'Neue Montreal'
		font-size: 15px
		font-style: normal
		font-weight: 400
		line-height: 18px
		letter-spacing: 0em
		text-align: center
		font-size 11px
		background: #fff
		background-clip: padding-box; /* !importanté */
		border: solid 2px transparent; /* !importanté */
		border-radius: 40px;
		padding 10px 20px
		position relative

		&:before {
			content: ''
			position: absolute
			top: 0; right: 0; bottom: 0; left: 0
			background #0ff
			z-index: -1
			margin: -2px
			border-radius: inherit
			background: linear-gradient(100.57deg, rgba(255, 83, 121, 0.5), rgba(255, 83, 121, 1) )
		}

</style>
