<template>
	<div class="LearnButton" :class="{darkMode:darkMode}">
		<div class="title" v-html="text" />
		<div class="illustrationContainer">
			<img v-if="illustration" :src="illustration" ref="illustration" class="illustration">
		</div>
		<div class="desc" v-html="desc" />
		<img src="/img/views/home/baguette.png" class="baguette baguette1" ref="baguette1">
		<img src="/img/views/home/baguette.png" class="baguette" ref="baguette2">
	</div>
</template>

<script>
import { gsap, Quad } from 'gsap/all'

export default {
	name: 'LearnButton',
	props: {
		text: {
			type: String,
			default: ''
		},
		illustration: {
			type: String,
			default: ''
		},
		desc: {
			type: String,
			default: ''
		},
		darkMode: {
			type: Boolean,
			default: false
		},
		background: {
			type: String,
			default: ''
		}
	},
	mounted () {
		gsap.set(this.$refs.baguette1, { rotate: 55, x: 90, y: -100 })
		gsap.set(this.$refs.baguette2, { rotate: 35, x: 150, y: -45 })
		if (this.background != '') {
			this.$el.style.background = this.background
		}
		this.show()
	},
	methods: {
		show () {
			const angle1 = (45 + 90) / 180 * Math.PI
			gsap.from(this.$refs.baguette1, { rotate: 55, x: 90 + Math.cos(angle1) * -300, y: -100 + Math.sin(angle1) * -130, delay: 1 })
			gsap.from(this.$refs.illustration, { rotate: 1, duration: 3 })
			const angle2 = (45 + 90) / 180 * Math.PI
			gsap.from(this.$refs.baguette2, { ease: Quad.easeOut, rotate: 35, x: 150 + Math.cos(angle2) * -300, y: -45 + Math.sin(angle2) * -300, delay: 1 })
		},
		hide () {

		}
	}
}
</script>

<style lang="stylus" scoped>
.LearnButton
	padding 20px
	background: linear-gradient(180deg, rgba(0, 151, 254, 0.5) 0%, #0097FE 100%);
	box-shadow: 0px 18px 63px rgba(249, 27, 91, 0.09), 0px 4.02054px 14.0719px rgba(249, 27, 91, 0.0536497), 0px 1.19702px 4.18956px rgba(249, 27, 91, 0.0363503);	border-radius: 15px;
	user-select none
	margin-bottom 10px
	color #fff
	will-change transform, opacity
	.illustration
		width 120px
		height auto
		position absolute
		top 20px
		left 2px
		z-index 10
	.illustrationContainer
		position relative
		min-height: 130px
	.title
		font-family: 'NanumGothic';
		font-size: 15px;
		font-style: normal;
		font-weight: 800;
		line-height: 18px;
		letter-spacing: -0.02em;
		text-align: left;
	.desc
		margin-top 20px
		font-family: 'HK Grotesk Pro';
		font-size: 10px;
		font-style: normal;
		font-weight: 400;
		line-height: 13px;
		letter-spacing: -0.03em;
		text-align: left;
	h4
		margin-top 10px
		color #333
		font-size 12px
		padding 0
		margin-bottom 0
	&.darkMode
		color #000
		.desc
			color #828282
	.baguette
		width 22px
		height: 257px
		position: absolute
		top 0
		left 0
		z-index 5
	.baguette1
		z-index 15
</style>
