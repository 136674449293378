<template>
	<div @click="onClick" class="about">
		<img ref="img" src="img/views/home/icon-about.svg">
		<span ref="text">Kimbapp</span>
	</div>
</template>

<script>
import { Expo, gsap, Quad } from 'gsap/all'
import router from '@/router'

export default {
	name: 'AboutButton',
	mounted () {
		this.show()
	},
	methods: {
		onClick () {
			router.push('/about')
		},
		show () {
			gsap.fromTo(this.$refs.text, { x: -20, opacity: 0 }, { delay: 0.5, ease: Quad.easeOut, duration: 0.8, opacity: 1, x: 0 })
			gsap.fromTo(this.$refs.img, { opacity: 0, y: 20 }, { delay: 0.4, y: 0, ease: Expo.easeOut, duration: 0.8, opacity: 1 })
		},
		hide () {
			gsap.to(this.$refs.text, { delay: 0.1, ease: Quad.easeOut, duration: 0.3, y: -20, opacity: 0 })
			gsap.to(this.$refs.img, { delay: 0.2, ease: Quad.easeOut, duration: 0.3, y: -20, opacity: 0 })
		}
	}
}
</script>

<style lang="stylus" scoped>
.about
	img
		width 32px
		height 32px
		display inline-block
		vertical-align middle
		opacity 0
		will-change opacity

	color #000
	cursor pointer
	position absolute
	top 20px
	left 20px
	user-select none
	font-size 11px
	span
		will-change transform
		margin-left 14px
		font-family: NanumGothic;
		font-size: 15px;
		font-style: normal;
		font-weight: 700;
		line-height: 15px;
		letter-spacing: -0.02em;
		text-align: left;
		display inline-block

</style>
