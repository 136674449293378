<template>
	<div id="home">
		<CloudBackground ref="cloud" />
		<AboutButton ref="about" />
		<ProfileButton ref="profile" />
		<TextKR class="title" kr="안녕하세요!" ref="title">
			Welcome!
		</TextKR>
		<FeaturePush ref="feature" />
		<div class="row">
			<div class="column">
				<HomeButton
					ref="learnBtn"
					:text="'BOOK<br/>A TEACHER'"
					:illustration="'/img/views/home/teacher-illustration.png'"
					:desc="'Practice and improve your korean faster with our super teachers!'"
					@click="toBooking"
				/>
				<HomeButton
					ref="plusBtn"
					:text="'KIMBAPP+'"
					:desc="'Get Kimbapp+ and unlock a new level.'"
					:background="'#FFF'"
					:dark-mode="true"
					@click="toKimbappPlus"
				/>
			</div>
			<div class="column col2">
				<LearnButton
					ref="bookingBtn"
					:text="'LEARN'"
					:desc="'Learn and exercice your Korean.'"
					:illustration="'/img/views/home/learn-kimbap.png'"
					:background="'linear-gradient(180deg, rgba(255, 83, 121, 0.5) 0%, #FF5379 100%)'"
					@click="toLearn"
				/>
			</div>
		</div>
		<InviteFriendButton ref="friend" />
	</div>
</template>

<script>

import TextKR from '@/components/TextKR'
import AboutButton from '@/components/AboutButton'
import ProfileButton from '@/components/ProfileButton'
import CloudBackground from '@/components/CloudBackground'
import FeaturePush from '@/components/FeaturePush'
import { Quad, TimelineMax } from 'gsap/all'
import router from '@/router'
import InviteFriendButton from '@/components/InviteFriendButton.vue'
import HomeButton from '@/components/home/HomeButton.vue'
import LearnButton from '@/components/home/LearnButton.vue'

export default {
	name: 'Home',
	components: {
		TextKR,
		ProfileButton,
		CloudBackground,
		HomeButton,
		LearnButton,
		FeaturePush,
		AboutButton,
		InviteFriendButton
	},
	methods: {
		toKimbappPlus () {
			// router.push('KimbappPlus')
		},
		toLearn () {
			router.push('/learn')
		},
		toProfile () {
			router.push('/profile')
		},
		toBooking () {
			router.push('/booking')
		},
		transitionIn () {
			const tl = new TimelineMax({ delay: 0.5 })
			tl.staggerFromTo([this.$refs.learnBtn.$el, this.$refs.bookingBtn.$el, this.$refs.plusBtn.$el], 0.5, { opacity: 0, y: 40 }, { opacity: 1, y: 0, ease: Quad.easeOut, duration: 1.2 }, 0.2)
		},
		transitionOut () {
			this.$refs.cloud.hide()
			this.$refs.title.hide()
			this.$refs.feature.hide()
			this.$refs.about.hide()
			this.$refs.profile.hide()
			this.$refs.friend.hide()
			const tl = new TimelineMax({ delay: 0 })
			tl.staggerTo([this.$refs.learnBtn.$el, this.$refs.bookingBtn.$el, this.$refs.plusBtn.$el], 0.4, { opacity: 0, y: -80, ease: Quad.easeIn }, 0.1, '+=0')
		}
	},
	beforeRouteEnter (to, from, next) {
		next(vm => {
			vm.transitionIn()
		})
	},
	beforeRouteLeave (to, from, next) {
		this.transitionOut()
		setTimeout(next, 600)
	}
}
</script>

<style lang="stylus" scoped>
#home
	padding-top 92px
	.title
		text-align left
		margin-left 20px
	.row {
		margin-top 45px
		display: flex;
		padding 0 11px
		column-gap: 11px;
	}
	.column {
		flex: 50%;
	}
	.col2{
		margin-top:60px
	}
</style>
